/* src/Card.css */
.credit-card {
    width: 300px;
    height: 180px;
    border-radius: 12px;
    background: linear-gradient(135deg, #4e54c8, #8f94fb);
    color: white;
    padding: 20px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin: 10px 10px 10px 10px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    opacity: 0.5; /* 기본 상태에서 흐릿하게 */
  }
  
  .credit-card.selected {
    transform: scale(1.04);
    margin: 10px;
    opacity: 1; /* 선택된 상태에서 완전하게 보이도록 */
  }
  
  .card-logo img {
    width: 60px;
  }
  
  .card-title {
    font-size: 1.2em;
  }

  .card-number {
    font-size: 1em;
    letter-spacing: 2px;
  }
  
  .card-holder,
  .card-expiry {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-holder span,
  .card-expiry span {
    font-size: 0.8em;
  }
  
  .holder-name,
  .expiry-date {
    font-size: 1em;
  }

  .check-mark {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    color: green;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  