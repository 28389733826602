.html-contents p {
    margin-bottom: 0;
}

.text-black {
    color: black;
}
ul.li-gap-4 li + li {
    margin-top: 4px;
}
.html-contents ol {
    padding-left : 16px;
}